@accordion-padding-horizontal: 29px;
@accordion-padding-vertical: 24px;

.accordion-wrapper {
  .accordion-pane {
    background: #fff;
    border-right: 1px solid #bfbfbf; //TODO color as variable
    border-bottom: 1px solid #bfbfbf; //TODO color as variable
    margin-bottom: 4px;
    border-radius: @border-radius-base;

    .accordion-heading {
      padding: 0 29px;

      a {
        display: block;
        padding: @accordion-padding-vertical 0;
        font-size: @font-size-h4;
        color: @text-color;

        .accordion-icon-container {
          margin-right: @accordion-padding-horizontal;

          span {
            // animation duration from: /node_modules/bootstrap/js/collapse.js:35
            transition: all 350ms ease-in-out;
          }
        }

        &:hover,
        &:focus {
          opacity: 1;
        }

        &.collapsed {
          color: @gray-light;
        }

        &[aria-expanded="true"] {
          .accordion-icon-container {
            span {
              transform: rotate(90deg);
            }
          }
        }
      }
    }

    .accordion-body {
      .accordion-content {
        padding: @accordion-padding-vertical @accordion-padding-horizontal;
      }
    }
  }
}
