.career-offer {
  max-width: 1170px;
  margin: 0 auto;
  padding-top: 40px;

  .career-offer-item-header {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .career-offer-back-link {
    margin-bottom: 40px;
  }

  .career-offer-glyphicon {
    margin-right: 3.5px;
  }

  .career-offer-apply {
    margin-top: 20px;
    max-width: 360px;
    display: flex;
    gap: 10px;
  }

  .country-dropdown-container {
    justify-content: flex-start;
    margin-top: 20px;
  }

  &-container {
    position: relative;

    .lds-dual-ring {
      position: absolute;
      width: 100%;
      margin: 0;
      display: flex;
      justify-content: center;
    }
  }

  ul:not(.country-dropdown-container ul) {
    list-style-type: none;
    padding-left: 0;
    margin: 0;

    li {
      position: relative;
      margin-bottom: 20px;
      padding-left: 30px;
      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 12px;
        transform: translateY(-50%);
        width: 30px;
        height: 17px;
        background: url('https://www.paysera.com/v2/images/render/2502/3157?width=30&height=30') no-repeat center/cover;
      }

      ul {
        list-style-type: disc;
        padding-left: 20px;
        padding-top: 10px;

        li {
          padding-left: 0;

          &::before {
            display: none;
          }
        }
      }
    }
  }
}
