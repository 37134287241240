@media print {
    // http://stackoverflow.com/questions/2162929/in-firefox-when-printing-a-page-with-anchor-tags-the-link-location-is-printing
    a[href]:after{
        content:"";
    }

    html,
    body {
        height: auto;
    }

    body {
        font-size: 10px;
        min-height: 0;
        height: auto;
    }

    main {
        margin: 0 auto;
        min-height: 0;
    }

    .content-wrapper {
        padding-top: 10px;
        padding-bottom: 10px;

        h1 {
            font-size: 16px;
        }

        h3 {
            font-size: 14px;
            margin-top: 10px;
        }

        h4 {
            font-size: 12px;
        }
    }

    a {
        text-decoration: underline!important;
    }

    .push {
        height: auto;
    }

    .nav-menu-wrapper,
    .sign-on-wrapper,
    .save-as-wrapper,
    .menu-toggle,
    .back-link,
    footer {
        display: none !important;
    }
}
