.select2-container--bootstrap {
    .select2-dropdown {
        border-color: @dropdown-border;
    }

    .select2-selection__arrow {
        display: none;
    }

    .select2-dropdown {
        z-index: 10000;
    }

    .select2-selection__rendered {
        a {
            color: @gray-light;

            &:hover {
                color: darken(@gray-dark, 5%);
            }
        }
    }

    .select2-selection:hover {
        background-color: @dropdown-link-hover-bg;
    }

    .select2-results {
        a {
            color: @gray-light;
        }
    }

    .select2-results__options {
        .select2-results__option:hover {
            background-color: @dropdown-link-hover-bg;
            color: @dropdown-link-hover-color;
        }
    }

    .select2-results__option--highlighted[aria-selected] {
        background-color: @dropdown-bg;
        color: darken(@gray-dark, 5%);
    }

    .select2-results__option[aria-selected=true] {
        background-color: @dropdown-bg;
        color: darken(@gray-dark, 5%);
    }
}

.localization-popover {
    .caret {
        position: absolute;
        top: 50%;
        right: 8px;
    }
}

.select2-container--bootstrap.select2-container--focus .select2-selection,
.select2-container--bootstrap.select2-container--open .select2-selection {
    box-shadow: none;
    border-color: @dropdown-border;
}
