.btn {
    &.btn-success-outline {
        background-color: transparent;
        border-color: @dodger-blue;
        color: @dodger-blue;

        &:hover {
            .btn-success();
        }
    }
}
