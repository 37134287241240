.feature-flag-container {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  z-index: 1001;

  > div {
    margin: 0;
  }
}
