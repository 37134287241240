.list-link {
    a {
        display: inline-block;
        margin-bottom: 5px;

        span {
            margin-right: 8px;
        }
    }
}
