footer {
    color: @color-light-blue;

    a {
        color: @color-light-blue;

        &:hover {
            color: @color-light-blue;
        }
    }

    .footer-column {
        .footer-row {
            margin-bottom: 20px;

            h1, h2, h3, h4, h5, h6 {
                text-transform: uppercase;
                color: #fff;
                font-weight: 700;
                margin: 0 0 30px 0;
            }
        }
    }

    .footer-top {
        padding: 65px 0;
        background: @oxford-blue;

        .container {
            @media (min-width: @screen-md-min) {
                background: url('../../public/images/backgrounds/map.png') top right no-repeat;
            }
        }

        @media (max-width: @screen-md-min) {
            .footer-column {
                &.last {
                    text-align: center;

                    .footer-menu {
                        margin: 0 auto;
                    }
                }
            }
        }
    }

    .footer-bottom {
        padding: 35px 0;
        background: @color-dark-blue;
    }

    .footer-heading {
        text-transform: uppercase;
        color: #fff;
        font-weight: 700;
        margin: 0 0 30px 0;
    }
}
