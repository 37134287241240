.slider-heading {
    margin: 0 auto 35px;

    a {
        display: inline-block;
        color: #fff;

        &:hover,
        &:active,
        &:focus {
            outline: 0;
            color: #fff;
            text-decoration: none;
        }

        .slider-heading-content {
            position: relative;
            display: inline-block;

            .title {
                font-size: @font-size-large;
                font-weight: 400;
                line-height: 1.1;
                color: #fff;
                margin: 0;
            }

            .contents {
                display: block;
                margin-bottom: 5px;
            }
        }

        &.selected {
            padding: 10px 0;

            .title {
                font-size: 48px;
                font-weight: 700;
                color: @link-color;
                text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
            }

            .contents {
                font-size: 26px;
                font-weight: 700;
                color: @link-color;
                text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
            }

            hr {
                display: none;
            }
        }
    }

    hr {
        margin: 0;
        padding: 0;
    }
}
