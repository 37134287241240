.more-link-wrapper {
  padding: 20px 0;
  text-align: center;

  a {
    font-size: 1.5em;

    span {
      display: inline-block;
      vertical-align: middle;
      margin: 0 0 0 5px;
      font-size: 0.6em;
    }
  }
}