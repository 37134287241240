.btn-cta {
  white-space: normal;
  text-transform: uppercase;

  span {
    display: inline-block;
    vertical-align: middle;
    font-size: 0.6em;
    margin-left: 10px;
    margin-top: -3px;
  }
}
