.alert-block {
    display: block;
    margin: 0 auto;
    text-align: center;
    padding: 10px 0 0 0;

    .decoration {
        text-align: center;
        padding: 8px 0 0 0;
        overflow: hidden;

        span {
            display: inline-block;
            position: relative;
            top: -8px;
            color: @link-color;
        }

        hr {
            border-color: @gray-light;
            margin: 0;
            padding: 0;
            width: 50%;

            &.pull-left {
                margin-left: -20px;
            }

            &.pull-right {
                margin-right: -20px;
            }
        }
    }

    .text-content {
        margin: 0 20px 20px;
    }
}
