.list-spacious-item {
    margin-bottom: 10px;

    &:last-of-type {
        margin-bottom: 0;
    }
}

ul {
    &.list-spacious {
        .list-unstyled();

        li {
            .list-spacious-item();
        }
    }
}

ol {
    &.list-spacious {
        li {
            .list-spacious-item();
        }
    }
}

