@media (max-width: @screen-md-min) {

    body.prepare-animation {
        overflow-x: hidden;
    }

    .prepare-animation {
        .nav-menu-wrapper {
            display: block !important;
            position: fixed;
            top: 0;
            left: -300px;
            width: 300px;
            height: 100%;
            border-right: 1px solid @gray-lighter;
            background: #fff;
            z-index: 999;
            overflow-y: scroll;
        }
    }

    .csstransitions {
        // add transitions if supported
        .nav-menu-wrapper {
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;

            -webkit-transform: translateX(0);
            transform: translateX(0);

            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);

            // animation type
            -webkit-transition: 300ms ease all;
            transition: 300ms ease all;
        }

        .prepare-animation.show {
            .nav-menu-wrapper {
                -webkit-transform: translateX(300px);
                transform: translateX(300px);

                transform: translate3d(300px, 0, 0);
                -webkit-transform: translate3d(300px, 0, 0);
            }
        }
    }

    // fallback it there's no css transitions
    .no-csstransitions {
        .prepare-animation.show {
            .nav-menu-wrapper {
                left: 0;
            }
        }
    }
}

//append to body to trigger css3 animation: prepare-animation show
