header {
    border-bottom: 1px solid @gray-lighter;
}

.header {
    padding-top: 15px;
    padding-bottom: 15px;

    a {
        color: @gray-base;
    }

    .menu-toggle {
        display: none;
        vertical-align: middle;

        .icon-bar {
            background-color: @brand-primary;
        }

        @media (max-width: @screen-sm-max) {
            display: inline-block;

            .navbar-toggle {
                display: inline-block;
                margin-right: 0;
            }
        }
    }

    .sign-on-wrapper {
        height: @nav-height;
        line-height: @nav-height;
        text-transform: uppercase;
        text-align: right;

        @media (min-width: @screen-md-min) {
            margin: 0 -20px;
        }

        a {
            display: inline-block;

            span {
                font-size: @font-size-base;
                vertical-align: middle;
            }
        }

        .register {
            margin-right: 25px;

            @media (min-width: @screen-sm-min) {
                margin-right: 10px;
            }
        }
    }

    .logo-wrapper {
        display: inline-block;
        height: @nav-height;
        line-height: @nav-height;

        img {
            display: inline-block;
            width: @logo-width;
            height: auto;
            vertical-align: middle;
        }
    }
}
