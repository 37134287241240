@import "../../../../../../../node_modules/flag-icon-css/less/flag-icon.less";
@flag-icon-css-path: '../../../../../../../node_modules/flag-icon-css/flags';

.flag-icon-small {
  .flag-icon;
  width: 16px;
  height: 11px;
}

.flag-icon-regular {
    .flag-icon;
    width: 35px;
    height: 24px;
}

