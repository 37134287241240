// generated with https://google-webfonts-helper.herokuapp.com/fonts/open-sans?subsets=cyrillic,latin-ext,latin,cyrillic-ext
// http://stackoverflow.com/questions/8966740/how-to-host-google-web-fonts-on-my-own-server

/* open-sans-300 - cyrillic-ext_latin_latin-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-300.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Light'), local('OpenSans-Light'),
  url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-300.woff') format('woff'), /* Modern Browsers */
  url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-300italic - cyrillic-ext_latin_latin-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-300italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Light Italic'), local('OpenSansLight-Italic'),
  url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-300italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-300italic.woff') format('woff'), /* Modern Browsers */
  url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-300italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-regular - cyrillic-ext_latin_latin-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans'), local('OpenSans'),
  url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
  url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-italic - cyrillic-ext_latin_latin-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
  url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-italic.woff') format('woff'), /* Modern Browsers */
  url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-700 - cyrillic-ext_latin_latin-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-700.woff') format('woff'), /* Modern Browsers */
  url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-700italic - cyrillic-ext_latin_latin-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-700italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
  url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-700italic.woff') format('woff'), /* Modern Browsers */
  url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../public/fonts/open-sans-v13-cyrillic-ext_latin_latin-ext_cyrillic-700italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
