.background-image-wrapper {
  position: relative;
  padding: 70px 0;
  background-color: #374455;
  background-position: top center;
  background-repeat: no-repeat;

  strong {
    color: @link-color;
  }

  .decoration-line {
    position: absolute;
    left: 0;
    width: 100%;
    height: 6px; // bg image height
    background-position: top left;
    background-repeat: repeat-x;

    &.upper {
      top: -6px; // bg image height
      background-image: url('../../../public/images/decor-line-dark.png');
    }

    &.lower {
      bottom: 0;
      background-image: url('../../../public/images/decor-line-light.png');
    }
  }
}

.background-white {
  background-color: #fff;
}

.background-matrix {
  background-image: url('../../../public/images/backgrounds/matrix.jpg');
  color: @gray-lighter;

  h1, h2, h3, h4, h5, h6 {
    color: @gray-lighter !important;
  }

  hr {
    border-color: @gray-lighter !important;
  }
}

.background-facepile {
  background-position: center center;
  background-size: cover;
  background-image: url('../../../public/images/backgrounds/facepile.jpg');
  color: #fff;

  .decoration-line {
    display: none;
  }
}

.background-facepile-v2 {
  background-position: center center;
  background-size: cover;
  background-image: url('../../../public/images/backgrounds/facepile-v2.jpg');
  color: #fff;

  .decoration-line {
    display: none;
  }
}

.background-status-page {
  background-position: center center;
  background-size: cover;
  background-image: url('../../../public/images/backgrounds/status-banner@1x.jpg');
  color: #fff;

  .decoration-line {
    display: none;
  }
}
