// http://ryanfait.com/sticky-footer/
html, body {
    height: 100%;
}

main {
    min-height: 100%;
    margin: 0 auto -@footer-height;
}

.push {
    height: @footer-height;
}

.footer-top {
    min-height: 395px; //highest footer possible
}

// sticky footer only on lg resolution
@media (max-width: @screen-lg-min) {
    main {
        margin: 0 auto;
    }

    .push {
        height: auto;
    }

    .footer-top {
        min-height: 0;
    }
}
