.make-classes(@list, @suffix: ~'') {
    .iter(length(@list));
    .iter(@i) when (@i > 0) {
        .iter(@i - 1);
        @pair:  extract(@list, @i);
        @key:   extract(@pair, 1);
        @value: extract(@pair, 2);
        .@{key}@{suffix} {
            background-image: url('../../../public/images/backgrounds/@{value}');
        }
    }
}
