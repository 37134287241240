.follower-link-container {
    position: relative;
    overflow: hidden;

    > a {
        position: absolute;
        bottom: -100px;
        left: -100px;
        display: none;
        visibility: hidden;
        height: 0;
        width: 0;
        opacity: 0;
    }
}
