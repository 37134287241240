nav {
  height: @nav-height;
  line-height: @nav-height;

  ul {
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      list-style-type: none;
      margin: 0 35px 0 0;

      @media (max-width: @screen-lg-min) {
        margin: 0 20px 0 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      &.current {
        font-weight: bold;
      }

      // temporary mobile menu styles
      @media (max-width: @screen-sm-max) {
        display: block;
        margin: 0;

        a {
          display: block;
          padding: 20px 0;
        }
      }
    }
  }

  .mobile-menu-header {
    padding-top: 15px;

    .mobile-menu-logo-wrapper {
      position: relative;
      height: @nav-height;
      line-height: @nav-height;
      margin: 0 auto 35px;

      img {
        display: inline-block;
        width: @logo-width;
        height: auto;
        vertical-align: middle;
      }

      .mobile-menu-hide {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        padding: 15px;
        font-size: 1.3em;
        line-height: 1em;
      }
    }

    .mobile-sign-on-links {
      border-bottom: 1px solid @gray-lighter;

      a {
        color: @gray-base;
        display: block;
        margin: 0 auto 35px;
      }
    }
  }

  @media (max-width: @screen-sm-max) {
    height: auto;
    line-height: 1em;
    margin: 0 -(@grid-gutter-width/2);
    text-align: center;
  }
}
