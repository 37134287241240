.required {
  &:after {
    content: "*";
    color: red;
    margin-left: 3px;
  }
}

.required-label {
  label {
    .required();
  }
}

