.table {
  thead {
    th {
      font-weight: 400;
      color: #fff;
      background: @color-ocean-blue;
      border-left: 1px solid @table-border-color;
      border-right: 1px solid @table-border-color;
      text-align: center;
    }
  }

  > thead > tr > th {
    vertical-align: middle;
    border-bottom: 1px solid @table-border-color;
  }

  td[data-type="currency"] {
    text-align: right;
  }
}
