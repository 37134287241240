.transformable-table {
  th[scope="row"] {
    text-align: center;
    vertical-align: middle;
  }

  td[scope="row"] {
    text-align: center;
    vertical-align: middle;
  }

  @media (max-width: @screen-sm-min) {
    background-color: #fff;

    &.table-striped {
      background-color: #fff;
    }

    thead {
      border: 0 none;
      clip: rect(1px, 1px, 1px, 1px);
      height: 1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    tbody,
    tr,
    th,
    td {
      display: block;
      padding: 0;
      text-align: left;
      white-space: normal;
    }

    tr {
      border: 2px solid @color-ocean-blue;
      margin-bottom: 1.5em;

      th[scope="row"],
      td[scope="row"] {
        border: none;
        background-color: @color-ocean-blue;
        color: #fff;
        text-align: left;
        vertical-align: middle;
        display: flex;
        justify-content: space-between;

        &:before {
          color: inherit;
        }
      }
    }

    td {
      text-align: right;
    }

    td[data-title]:before {
      content: attr(data-title);
      float: left;
      color: @gray-light;
    }
  }
}
