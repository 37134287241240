.cookie-consent-dialog-container {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  max-height: 100vh;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  padding: 16px;
}
@media (min-width: 1280px) {
  .cookie-consent-dialog-container {
    max-height: 99.5vh;
  }
}
.cookie-consent-dialog-container .body-text {
  margin: 0;
  font-size: 1.3rem;
  line-height: 1.43;
}
.cookie-consent-dialog-container .vertical-gap-md {
  padding: 8px 0;
}
.cookie-consent-dialog-container .customize-button {
  font-size: 12px;
}
.cookie-consent-dialog-container .customize-button > .collapse-icon {
  top: 3px;
  left: 5px;
  cursor: pointer;
}
.cookie-consent-dialog-container .row.selection-controls-row {
  margin-top: 15px;
}
.cookie-consent-dialog-container .panel-borderless {
  border: 0;
}
.cookie-consent-dialog-container .tab-content .tab-description {
  margin: 15px 0;
}
@media (max-width: 768px) {
  .cookie-consent-dialog-container .cookie-consent-buttons .col-xs-12:last-child {
    margin-top: 10px;
  }
  .cookie-consent-dialog-container #cookies-category-tabpanel {
    max-width: calc(100vw - 30px);
  }
}
