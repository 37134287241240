// http://stackoverflow.com/questions/23985018/simple-css-animation-loop-fading-in-out-loading-text
.keyframes(
flicker,
{ 0% {
    opacity: 0;
} 50% {
      opacity: 1;
  } 100% {
        opacity: 0;
    } }
);

.localization-section {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px 0;
    background-color: rgba(0, 0, 0, 0.5);

    .container {
        position: relative;

        .scroll-to {
            display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -14px 0 0 -16px;

            &:hover {
                opacity: 1;
            }

            &:active,
            &:focus {
                outline: 0;
            }

            @media (min-width: @screen-md-min) {
                display: block;
                .animation(flicker 2000ms infinite);
            }
        }
    }

    .language-nav {
        display: block;
        margin: 0;
        padding: 0;

        > li {
            display: inline-block;
            margin: 0;
            padding: 0;

            &.separator {
                color: #fff;
                margin: 0 15px;
            }

            > a {
                color: #fff;
                text-transform: capitalize;

                &:hover {
                    color: #fff;
                }
            }

            .localization-popover-toggle {
                > span {
                    color: #fff;
                    font-size: 1.5em;
                    vertical-align: middle;
                    cursor: pointer;
                }
            }
        }
    }
}

.localization-popover-toggle-footer {
    text-align: right;

    >span {
        border: 1px solid @color-light-blue;
        padding: @padding-xs-vertical @padding-small-horizontal;
    }

    span {
        display: inline-block;

        .dropup,
        .dropdown {
            bottom: 2px;
        }
    }
}

@media (max-width: @screen-xs-max) {
    .localization-popover-toggle-footer {
        text-align: center;
        padding-top: 5px;
    }

    .localization-section {
        .language-nav {
            > li {
                &.separator {
                    visibility: hidden;
                    margin: 0 1px;
                }
            }
        }
    }
}
