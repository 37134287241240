.footer-menu {
    display: block;
    margin: 0;
    padding: 0;

    li {
        display: block;
        padding: 0;
        margin: 0 0 20px 0;
        list-style-type: none;

        &:last-of-type {
            margin: 0;
        }
    }

    @media (max-width: @screen-md-min) {
        margin: 0 auto 50px;
    }
}

.footer-menu-horizontal {
    a {
        display: inline-block;
        padding: 0 10px 0 0;
    }
}

@media (max-width: @screen-sm-min) {
    .footer-menu-horizontal {
        text-align: center;

        a {
            display: block;
            padding: 5px 0;
        }
    }
}
