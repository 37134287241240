.featurette {
    .featurette-first-column {
        .make-sm-column(7);
        .make-xs-column(12);
    }

    .featurette-second-column {
        .make-sm-column(5);
        .make-xs-column(12);
    }

    &.featurette-text-light {
        color: #fff;
    }

    &.featurette-second-column-wider {
        .featurette-first-column {
            .make-sm-column(5);
            .make-xs-column(12);
        }

        .featurette-second-column {
            .make-sm-column(7);
            .make-xs-column(12);
        }
    }

    .featurette-heading {
        font-weight: 500;
        margin-bottom: @grid-gutter-width;
        margin-top: 0;
    }

    .featurette-image-container {
        img {
            .img-responsive-inline-block();
        }
    }

    &.featurette-image-center {
        .featurette-image-container {
            text-align: center;
        }
    }

    &.featurette-image-opposite-text {
        .featurette-image-container {
            text-align: right;
        }
    }

    @media (min-width: @screen-sm-min) {
        > .row {
            display: flex;
            align-items: center;
        }
    }

    @media (max-width: @screen-xs-max) {
        .featurette-first-column {
            margin-bottom: @grid-gutter-width;
        }

        .featurette-image-container {
            text-align: center !important;
        }
    }
}
