.heading-block-wrapper {
    text-align: center;

    .heading-block {
        position: relative;
        display: inline-block;
        margin: 0 0 20px 0;

        .decoration {
            text-align: center;
            padding: 8px 0 0 0;
            overflow: hidden;

            span {
                display: inline-block;
                position: relative;
                top: -8px;
            }

            hr {
                border-color: @headings-color;
                margin: 0;
                padding: 0;
                width: 50%;

                &.pull-left {
                    margin-left: -20px;
                }

                &.pull-right {
                    margin-right: -20px;
                }
            }
        }

        .heading-link-wrapper {
            a {
                color: @link-color;
            }
        }
    }
}
