.career {
  &:not(:last-child) {
    margin-bottom: 60px;
  }

  .career-title {
    font-weight: bold;
  }

  .career-location {
    color: @gray-light;
  }

  hr {
    border: 1px, solid, @gray-bright;
  }
}

.careers {
  .filters-container {
    display: flex;
    gap: 16px;
  }
}

.department-dropdown-container,
.country-dropdown-container {
  .caret {
    margin-bottom: 4px;
  }
}

.department-dropdown-item,
.country-dropdown-item {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}
