.featured-heading-wrapper {
  margin: 0 auto 15px;

  .featured-heading {
    margin-top: 0;
    margin-left: 100px;
    line-height: 1em;
    color: @link-color;
  }

  .featured-heading-icon {
    display: block;
    float: left;
    max-width: 80px;
    height: auto;
  }

  .featured-heading-description {
    color: #fff;
    margin-left: 100px;
  }
}
