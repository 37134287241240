.block-icon-text-horizontal {
    display: table;
    width: 100%;

    & + & {
        margin-top: 30px;
    }

    .image-wrapper {
        display: inline-block;
        padding-right: 15px;

        > img {
            max-width: none;
        }
    }

    .text-wrapper {
        width: 100%;

        *:first-child {
            margin-top: 0;
        }

        *:last-child {
            margin-bottom: 0;
        }
    }

    > div {
        display: table-cell;
        vertical-align: middle;
    }
}

@media (max-width: @screen-md-min) {
    .block-icon-text-horizontal {
        margin: 0 auto 20px;

        .text-wrapper {
            text-align: center;
        }
    }
}
