.carousel-fade {
    .carousel-inner {
        .item {
            transition-property: opacity;
        }

        .item,
        .active.left,
        .active.right {
            opacity: 0;
        }

        .active,
        .next.left,
        .prev.right {
            opacity: 1;
        }

        .next,
        .prev,
        .active.left,
        .active.right {
            left: 0;
            transform: translate3d(0, 0, 0);
        }
    }
}

.slideshow-section {
    position: relative;
    width: 100%;

    .carousel,
    .carousel-inner,
    .item {
        width: 100%;
        height: 100%;
    }

    .item {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: @oxford-blue;

        .item-content-wrapper {
            display: table;
            width: 100%;
            height: 100%;

            .item-content-cell {
                display: table-cell;
                vertical-align: middle;
                text-align: center;
                color: #fff;
                padding: 90px 60px;
            }
        }
    }

    .carousel-control {
        display: none;
    }

    // bg images only shown from md resolution
    @media (min-width: @screen-md-min) {
        .carousel-control {
            display: block;
        }

        .make-classes(@paysera-slides-backgrounds);
    }
}

.carousel-inner {
    & > .item {
        transition: transform 2s ease-in-out;
    }
}

@media (min-height: 800px) {
    .slideshow-section {
        height: 100vh;
    }
}

@media (max-width: @screen-xs-max) {
    .slideshow-section {
        .item {
            .item-content-wrapper {
                .item-content-cell {
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
        }
    }
}
