@color-dot-size: 0.9em;

.color-dot {
    display: inline-block;
    height: @color-dot-size;
    width: @color-dot-size;
    background: #fff;
    border: 1px solid @bg-color-base;
    border-radius: 50%;
    margin-right: 5px;

    &.color-dot-green {
        background: @brand-success;
        border-color: @brand-success;
    }

    &.color-dot-yellow {
        background: @brand-warning;
        border-color: @brand-warning;
    }

    &.color-dot-blue {
        background: @brand-info;
        border-color: @brand-info;
    }
}
