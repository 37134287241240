.check-list {
  display: inline-block;
  padding-left: 0;

  li {
    display: block;
    min-height: 24px;
    list-style-type: none;
    padding-left: 45px;
    margin: 0 auto 20px;
    text-align: left;
    background: url('../../../public/images/typo/check.png') 0 -1px no-repeat;
  }
}
