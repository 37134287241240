.localization-popover {
  max-width: 100%;
  width: 235px;

  .control-label {
    font-weight: 400;
    text-align: left;
  }

  .dropdown-toggle {
    position: relative;
    text-align: left;
    color: @gray-light;
    white-space: normal;
    display: inline-flex;
    align-items: center;

    .caret {
      position: absolute;
      top: auto;
      right: 8px;
    }

    .flag-icon-small {
      margin-right: 8px;
      flex-shrink: 0;
    }
  }

  .dropdown-menu {
    .flag-icon-small {
      margin-right: 5px;
    }
  }
}
