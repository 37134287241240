.cycle-slideshow-wrapper {
    position: relative;
    margin: 0 auto 20px;

    .cycle-control {
        position: absolute;
        top: 0;
        height: 100%;
        font-size: 50px;
        color: @gray-light;

        &.cycle-prev {
            left: -40px;
        }

        &.cycle-partners-prev {
            left: -40px;
            top: 50%;
            margin-top: -25px
        }

        &.cycle-next {
            right: -40px;
        }

        &.cycle-partners-next {
            right: -40px;
            top: 50%;
            margin-top: -25px
        }

        &:hover {
            opacity: 0.3;
        }

        span {
            position: relative;
        }

        .cycle-top {
            top: 70px;
        }

        .cycle-partners-top {
            display: block;
        }
    }

    .cycle-overlay {
        text-align: center;
        min-height: 180px;

        div {
            display: inline-block;
            position: relative;
            font-size: @font-size-large;
            padding: 20px 0;
            color: @gray;

            span {
                position: absolute;
                width: 25px;
                height: 21px;

                &:first-of-type {
                    bottom: 0;
                    right: -40px;
                    background: url('../../public/images/slider/quote-open.png') center center no-repeat;
                }

                &:last-of-type {
                    top: 0;
                    left: -40px;
                    background: url('../../public/images/slider/quote-close.png') center center no-repeat;
                }
            }
        }
    }
}

.cycle-slideshow {
    margin: 0 auto 40px;

    &.cycle-carousel, &.cycle-partners-carousel {
        .cycle-slide {
            padding: 25px 0 !important;

            img {
                display: block;
                width: 140px;
                height: auto;
                margin: 0 auto;
                opacity: 0.3;
                transition: all 500ms ease-in-out;
                transform: scale(0.8);
            }

            &.active-slide-style {
                img {
                    border: 4px solid @link-color;
                    opacity: 1;
                    transform: scale(1.2);
                }
            }

            &.bigger-slide {
                img {
                    transform: scale(1);
                }
            }
        }

        .cycle-partners-slide {
            padding: 25px 0 !important;

            img {
                display: block;
                width: 200px;
                height: auto;
                margin: 0 auto;
                opacity: 1;
            }
        }
    }
}

@media (max-width: @screen-sm-max) {
    .cycle-slideshow {
        &.cycle-carousel {
            .cycle-slide {
                img {
                    transform: scale(0.6);
                }

                &.active-slide-style {
                    img {
                        transform: scale(1);
                    }
                }

                &.bigger-slide {
                    img {
                        transform: scale(0.8);
                    }
                }
            }
        }
    }
}

@media (max-width: @screen-xs-max) {
    .cycle-slideshow-wrapper {
        .cycle-control {
            &.cycle-prev {
                left: 0;
            }

            &.cycle-partners-prev {
                right: 0;
            }

            &.cycle-next {
                left: 0;
            }

            &.cycle-partners-next {
                right: 0;
            }
        }

        .cycle-overlay {
            text-align: center;
            margin: 0 40px;
        }
    }

    .cycle-slideshow {
        margin-left: 25px;
        margin-right: 25px;

        &.cycle-carousel {
            .cycle-slide {
                vertical-align: middle;

                img {
                    opacity: 0;
                    width: 100%;
                    height: auto;
                    min-width: 150px;
                }

                &.active-slide-style {
                    .slide-image-container {
                        display: flex;
                        justify-content: center;
                    }

                    img {
                        border: 2px solid @link-color;
                        transform: scale(1.2);
                        max-height: 180px;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}
