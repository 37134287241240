.timeline-wrapper {
    max-width: 830px;
    margin: 0 auto;

    .timeline-item {
        position: relative;
        padding: 15px 0;

        .timeline-item-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url('../../../public/images/timeline/line.png') top center repeat-y;
        }

        &:last-child {
            .timeline-item-bg {
                height: 50%;
            }
        }

        &:first-child {
            .timeline-item-bg {
                top: auto;
                bottom: 0;
                height: 50%;
            }
        }

        .circle {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 10px;
            height: 10px;
            margin: -5px 0 0 -5px;
            border-radius: 5px;
            background: #236c9d;
        }

        .year {
            position: absolute;
            top: 50%;
            line-height: 1em;
            width: 110px;
            font-size: 22px;
            margin-top: -12px;
            color: @link-color;
        }

        .event {
            width: 50%;

            .event-content {
                position: relative;
                padding: 15px;
                background: #fafafa;
                border: 1px solid #eeeeee;
                border-radius: 3px;

                .arrow {
                    display: none;
                    position: absolute;
                    top: 50%;
                    margin-top: -6px;
                }
            }
        }

        &:nth-child(even) {
            .year {
                left: 50%;
                margin-left: -110px;
            }

            .event {
                float: right;
                text-align: left;

                .event-content {
                    margin: 0 0 0 25px;

                    .arrow.even {
                        display: block;
                        left: 0;
                        margin-left: -11px;
                    }
                }
            }
        }

        &:nth-child(odd) {
            .year {
                right: 50%;
                margin-right: -110px;
                text-align: right;
            }

            .event {
                float: left;
                text-align: right;

                .event-content {
                    margin: 0 25px 0 0;

                    .arrow.odd {
                        display: block;
                        right: 0;
                        margin-right: -11px;
                    }
                }
            }
        }
    }
}
