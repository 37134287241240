.drop-zone {
    .drop-zone-container {
        padding: 40px;
        min-height: 200px;
        border: 1px dashed @input-border;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        background-color: @input-bg;
        color: @input-color;
        user-select: none;

        @media (max-width: @screen-sm-max) {
            padding: 20px;
        }

        .upload-icon {
            margin-bottom: 10px;
            font-size: 80px;

            @media (max-width: @screen-sm-max) {
                margin-bottom: 0;
            }
        }

        .drop-zone-title {
            margin-bottom: 10px;
            color: @input-color;
        }

        &.drag-active {
            background-color: darken(@input-bg, 10%);
        }

        .select-file {
            text-align: center;
            border: solid 1px @input-border;
            border-radius: 5px;
            padding: 5px 50px;
            margin: 20px 0 0;

            &:hover {
                background-color: darken(@input-bg, 10%);
            }
        }
    }
}
