
.icon-user:before { content: '\e800'; } /* '' */
.icon-user-plus:before { content: '\e801'; } /* '' */
.icon-mail:before { content: '\e802'; } /* '' */
.icon-videocam:before { content: '\e803'; } /* '' */
.icon-ok:before { content: '\e804'; } /* '' */
.icon-comment:before { content: '\e805'; } /* '' */
.icon-eye:before { content: '\e806'; } /* '' */
.icon-tools:before { content: '\e807'; } /* '' */
.icon-edit:before { content: '\e808'; } /* '' */
.icon-lock:before { content: '\e809'; } /* '' */
.icon-lock-open-alt:before { content: '\e80a'; } /* '' */
.icon-picture:before { content: '\e80b'; } /* '' */
.icon-right-dir:before { content: '\e80c'; } /* '' */
.icon-attention:before { content: '\e80d'; } /* '' */
.icon-print:before { content: '\e80e'; } /* '' */
.icon-check:before { content: '\e80f'; } /* '' */
.icon-phone:before { content: '\e810'; } /* '' */
.icon-angle-right:before { content: '\e811'; } /* '' */
.icon-angle-down:before { content: '\e812'; } /* '' */
.icon-facebook-squared:before { content: '\e813'; } /* '' */
.icon-gplus-squared:before { content: '\e814'; } /* '' */
.icon-linkedin-squared:before { content: '\e815'; } /* '' */
.icon-youtube-squared:before { content: '\e816'; } /* '' */
.icon-link-1:before { content: '\e817'; } /* '' */
.icon-cog:before { content: '\e818'; } /* '' */
.icon-info-circled-1:before { content: '\e819'; } /* '' */
.icon-help-circled-1:before { content: '\e81a'; } /* '' */
.icon-clock:before { content: '\e81b'; } /* '' */
.icon-cancel:before { content: '\e81c'; } /* '' */
.icon-upload-cloud:before { content: '\f0ee'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-skype:before { content: '\f17e'; } /* '' */
.icon-trash:before { content: '\f1f8'; } /* '' */
