.text-section {
    padding-top: @grid-gutter-width;
    padding-bottom: @grid-gutter-width;

    &.text-section-spacing-2 {
        padding-top: @grid-gutter-width * 2;
        padding-bottom: @grid-gutter-width * 2;
    }

    &.text-section-spacing-3 {
        padding-top: @grid-gutter-width * 3;
        padding-bottom: @grid-gutter-width * 3;
    }

    &.text-section-spacing-4 {
        padding-top: @grid-gutter-width * 4;
        padding-bottom: @grid-gutter-width * 4;
    }

    &.darker {
        background: @gray-lighter;
    }

    &.soft-peach {
        background: @soft-peach;
    }

    &.foam {
        background: @color-foam;
    }

    &.bunting-blue {
        background: @color-bunting-blue;
    }

    &.fuchsia-pink {
        background: @color-fuchsia-pink;
    }

    &.oxford-blue {
        background: @oxford-blue;
        color: #fff;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: #fff;
        }

        hr {
            border-color: #fff !important;
        }
    }

    &.gradient-gray-to-right {
        background-image: linear-gradient(to right, #fff, @color-iron);
    }

    &.gradient-gray-to-left {
        background-image: linear-gradient(to left, #fff, @color-iron);
    }

    @media (max-width: @screen-xs-max) {
        padding-top: @grid-gutter-width !important;
        padding-bottom: @grid-gutter-width !important;
    }

    @media print {
        padding-top: @pdf_padding_top !important;
        padding-bottom: 0 !important;
    }
}
