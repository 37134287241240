.padded-block {

  &.xs-padding {
    padding: 5px 0;
  }

  &.sm-padding {
    padding: 10px 0;
  }

  &.md-padding {
    padding: 15px 0;
  }

  &.lg-padding {
    padding: 20px 0;
  }

  &.xlg-padding {
    padding: 25px 0;
  }

  &.xxlg-padding {
    padding: 30px 0;
  }
}
