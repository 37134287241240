.sub-content-wrapper {
    text-align: center;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    background-color: #fff;

    .featured-image {
        padding: 60px 0;

        .description {
            color: #fff;
            margin-bottom: (@line-height-computed / 2);
            padding: 15px 0;
        }
    }
}

// all background images shown only from md resolution and above
@media (min-width: @screen-md-min) {
    .sub-content-wrapper {
        height: 500px;
        background-image: url('../../../public/images/backgrounds/desktop-grayscale.jpg');
    }

    .layout-front-page {
        .sub-content-wrapper {
            height: 726px;
            background-position: center center;
            background-image: url('../../../public/images/backgrounds/desktop.jpg');
        }
    }
}
