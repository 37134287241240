.content-wrapper {
  a[target="_blank"] {
    &:after {
      font-family: 'Glyphicons Halflings';
      content: "\e164";
      padding-left: 5px;
    }
  }
  .vanilla-anchors {
    a[target="_blank"] {
      &:after {
        font-family: inherit;
        content: '';
        padding-left: 0;
      }
    }
  }
}
a {
  &:hover,
  &:focus {
    color: @link-color;
    text-decoration: none;
    opacity: 0.5;
  }

  &.btn:hover,
  &.btn:focus {
    opacity: 1;
  }
}
