.job-details {
  margin-top: 30px;

  .job-details-tile {
    text-transform: uppercase;
    font-weight: bold;
    color: @gray-light;
  }

  .job-details-item {
    margin-bottom: 10px;
  }

  .job-details-apply {
    display: flex;
    gap: 10px;
  }

  hr {
    border-top: 1px, solid, @gray-bright;
  }
}
