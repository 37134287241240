.featured-image-wrapper {
    display: table;
    width: 100%;
    height: 380px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-image: url('../../../public/images/backgrounds/mobile-default.jpg');

    &.auto {
        height: auto;
        padding: 20px 0;
    }

    &.size-2 {
        height: 620px;
    }

    .featured-image {
        display: table-cell;
        vertical-align: middle;

        .featured-heading {
            color: #fff;
            font-weight: 100;
            margin-top: 0;
        }

        .description {
            color: #fff;
            margin-bottom: (@line-height-computed / 2);
            padding: 15px 0;
        }
    }
}

.make-classes(@paysera-backgrounds-mobile, -background-mobile);

@media (min-width: @screen-md-min) {
    // all background images shown only from md resolution and above
    .make-classes(@paysera-backgrounds, -background);

    .featured-image-wrapper {
        .featured-image {
            .featured-heading {
                color: @link-color;

                &.featured-heading-white {
                    color: #fff;
                }
            }
        }
    }
}
