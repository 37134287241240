.text-block {
    margin-bottom: @grid-gutter-width;

    h1, h2, h3, h4, h5, h6 {
        font-weight: 400;
        font-size: @font-size-h4;
        color: @brand-primary;
    }

    // reserve height only from md resolution and above
    @media (min-width: @screen-md-min) {
        h1, h2, h3, h4, h5, h6 {
            min-height: ((@font-size-h4 + @line-height-base) * 2);
        }
    }
}
