.layout-front-page {
    header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        border-bottom: none;
        transition: background-color 0.5s ease;
        z-index: 1000;

        .logo-wrapper {
            .main {
                display: none;
            }
        }

        a {
            color: #fff;
        }

        .menu-toggle {
            .icon-bar {
                background-color: #fff;
            }
        }

        // mobile menu link color always should be always primary
        @media (max-width: @screen-sm-max) {
            nav {
                a {
                    color: @gray-base;
                }
            }
        }
    }
}

.opaque-header() {
    .layout-front-page {
        header {
            border-bottom: 1px solid @gray-lighter;
            background-color: #fff;

            a {
                color: @gray-base;
            }

            .menu-toggle {
                .icon-bar {
                    background-color: @gray-base;
                }
            }

            .logo-wrapper {
                .main {
                    display: inline-block;
                }

                .front-page {
                    display: none;
                }
            }
        }
    }
}

html:not([data-scroll='0']) {
    .opaque-header();
}

@media (max-width: @screen-sm-max) {
    .opaque-header();
}
