.thumbnail-wrapper {
  margin-bottom: 20px;

  .thumbnail {
    margin-bottom: 20px;
    background-color: transparent;

    .caption {
      display: block;
      text-align: center;
    }

    &:hover,
    &:focus,
    &:active {
      border-color: transparent;
      outline: 0;
    }
  }
}
