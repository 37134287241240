.feature-block {
  display: block;
  padding: 85px 65px;
  margin: 0 auto 25px;
  text-align: center;
  background: #fff;
  border-right: 1px solid #c9c9c9;
  border-bottom: 1px solid #c9c9c9;
  border-radius: @btn-border-radius-base;
  cursor: pointer;

  .feature-block-content {
    display: block;
    min-height: 195px;
  }

  .feature-icon-wrapper {
    display: block;
    margin: 0 auto 30px;

    .hover {
      display: none;
    }
  }

  .text-wrapper {
    display: block;
    color: @brand-primary;
    font-size: 1.3em;
  }

  &:hover {
    background: @link-color;
    text-decoration: none;
    opacity: 1;

    .feature-icon-wrapper {
      .default {
        display: none;
      }

      .hover {
        display: inline;
      }
    }

    .text-wrapper {
      color: #fff;
    }
  }
}
